import axios from "axios";
import CustomToast from "component/Toast";
import { store } from "index";
import { useState } from "react";
import { baseApi } from "utils/constants/config";
import { deleteNullinObj } from "utils/helper/data";

axios.interceptors.request.use(
    (config) => {
        var state = store.getState();
        var token = state.auth.user.access_token;

        if (token) {
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export const handleError = (e) => {
    if (e.response) {
        var { status } = e.response;
        if (status === 422) {
            if (e.response.data && e.response.data.errors) {
                const { errors } = e.response.data;
                for (const attribute in errors) {
                    if (errors.hasOwnProperty(attribute))
                        CustomToast.error(errors[attribute][0]);
                }
            }
        } else if (status === 403) {
            var url = e.response.config.url.split("/").pop();
            if (localStorage.getItem(url)) {
                if (e.response.config.method.toLowerCase() !== "get") {
                    CustomToast.error(
                        "Sorry, you are not authorized to perform this action",
                        5
                    );
                } else {
                    CustomToast.error(
                        "Sorry, you are not authorized to access this page",
                        5
                    );
                }
            } else {
                localStorage.setItem(url, true);
                if (e.response.config.method.toLowerCase() === "get") {
                    window.location.reload();
                }
            }
        } else if (status >= 400 && status < 500) {
            CustomToast.error(e.response.data.message);
        } else {
            CustomToast.error(e.message);
        }
    } else if (e.request) {
        CustomToast.error(e.message);
    } else {
        CustomToast.error(e.message);
    }
};

const CustomException = (e) => {
    const error = new Error(e);
    error.response = e.response;

    return error;
};

export const RequestApi = async (
    url,
    method = "get",
    data = {},
    msg = "",
    header = null
) => {
    try {
        var Param = {
            method,
            url: baseApi + "/" + url,
            data,
        };
        if (header) {
            Param.headers = header;
        }

        const req = await axios(Param);
        localStorage.removeItem(baseApi + "/" + url);
        if (msg) CustomToast.success(msg);
        return req;
    } catch (e) {
        if (msg !== null) handleError(e);
        throw CustomException(e);
    }
};

export const useAPI = (url, { method = "get", message = "" } = {}) => {
    const [loading, setLoading] = useState(false),
        [error, setError] = useState(null),
        fetchData = async ({ params = null, id = null, data = {} } = {}) => {
            setLoading(true);
            try {
                var URL = url;
                if (id) {
                    URL = url + "/" + id;
                }
                if (params) {
                    var urlParam = new URLSearchParams(
                        deleteNullinObj(params)
                    ).toString();
                    URL = url + "?" + urlParam;
                }
                var req = await RequestApi(URL, method, data, message);

                setLoading(false);
                if (!req.data) {
                    throw new Error();
                }
                return req.data;
            } catch (e) {
                setLoading(false);
                setError(e);
                console.log(e);
                throw CustomException(e);
                
            }
        };

    return {
        loading,
        error,
        setLoading,
        fetch: fetchData,
    };
};
