import CustomToast from "component/Toast";
import moment from "moment";
import { getFileInfo } from "./data";
import { config } from "utils/constants/config";

export const fromMoment = (date, format = "YYYY-MM-DD") => {
    if (!date) return;
    if (date.isMoment) {
        return date.format(format);
    } else {
        return moment(date).format(format);
    }
};

export const toMoment = (data, format = "YYYY-MM-DD") => {
    if (data) return moment(data, format);
    else {
        return null;
    }
};

export const numNormalize = (v) => parseInt(v.replace(/\./g, "")) || null;
export const currencyNormalize = (v) => {
    var num = parseInt(v.replace(/\./g, "")) || null;

    if (num) {
        num = num.toString();
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
        return num;
    }
};
export const floatNormalize = (v, dec = null) => {
    v = v.replace(/[^0-9.]/g, "");
    var dot = v.split(".");
    if (dot.length > 2) v = parseFloat(v);
    return v;
};

export const phoneNormalize = (v) => v.replace(/[^\d+]/g, "") || null;

export const isValidURL = (str) => {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\1-9\\d_.~+=-]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
};

export const toBase64 = (file) =>
    new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        //,type = file.name.split('.').pop().toLowerCase()
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

var arrayErrorFile = [],
    sendErrorMessage = (name, msg) => {
        console.log(arrayErrorFile.indexOf(name), arrayErrorFile, name);
        if (arrayErrorFile.indexOf(name) < 0) {
            CustomToast.error(msg);
            arrayErrorFile.push(name);
        }
    };
export const resetErrorFile = () => {
    arrayErrorFile = [];
};
export const validateFile = (
    file,
    typeSupported = ["jpg", "jpeg", "png", "pdf", "zip", "doc", "docx"],
    max = 20
) => {
    var { ext } = getFileInfo(file.name),
        isSupported = typeSupported.includes(ext);
    if (!isSupported) {
        sendErrorMessage(file.name, `${file.name} is not supported`);
        return false;
    }

    var isExceed = file.size / 1024 / 1024 > max;
    if (isExceed) {
        sendErrorMessage(
            file.name,
            `${file.name} exceeds the maximum limit of ${max}mb`
        );
        return false;
    }
    return true;
};

export const generateRange = (size, start = 0) => {
    return [...Array(size).keys()].map((i) => i + start);
};

export const filterSelectOption = (v, option) => {
    if (!option.children && !option.title) return false;
    
    var oType = typeof option.children,
        c = option.title
            ? option.title
            : oType === "object"
            ? option.children[0]
            : option.children;
    if(c){
        return c.toLowerCase().includes(v.toLowerCase());
    }else{
        return false
    }
    
};

export const startMonthFormat = (v) =>
    v.startOf("month").format(config.dateFormat);
