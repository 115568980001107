import { createSlice } from "@reduxjs/toolkit"

const initialUser = {
    id: 0,
    name: "",
    role: "",
    avatar: "",
    access_token: "",
    all_branch: []
}

export const initialState = {
    isAuthenticated: false,
    error: '',
    isLogout: false,
    user: initialUser
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        LogIn : (state, action) =>{
            state.isLogout = false
        },
        LogInSuccess : (state,action) =>{
            state.user = action.payload
            state.role = action.payload.role
            state.error = ""
            state.isAuthenticated = true
        },
        RefreshRole : (state,action) => {
            state.user.role = action.payload.role
            state.user.all_branch = JSON.stringify(action.payload.all_branch)
        },
        LogInError : (state, action) =>{
            console.log(action)
            state.isAuthenticated = false
            state.error = action.payload
        },
        UpdatePassword: (state,action) =>{
            state.loginInfo.password = action.payload
        },
        LogOut: (state,action) => {
            state.error = ''
            state.isLogout = true
            state.user = initialUser
            state.isAuthenticated = false
            //state = initialState
        }
    }
})

export const {LogIn, LogInSuccess, LogInError ,LogOut,UpdatePassword,RefreshRole} = authSlice.actions

export default authSlice.reducer
