import "styles/plugins.sass";
import "styles/app.sass";
import { ToastContainer } from "react-toastify";
import RouteProvider from "utils/route/Provider";

function App() {
    return (
        <>
            <RouteProvider />
            <ToastContainer
                closeButton={false}
                hideProgressBar={true}
                autoClose={3000}
                position="top-center"
            />
        </>
    );
}

export default App;
