import axios from "axios";
import { RefreshRole } from "store/slices/auth";
import { RequestApi } from "./api";
import { store } from "index";

export const DoLogIn = async (data) => {
    var req = await RequestApi("sso-callback", "post", data, null);
    if (req) {
        return req.data;
    } else {
        console.log(req);
    }
};

export const LoginAfterRefresh = async (s) => {
    var state = store.getState();
    if (state.auth.isAuthenticated) {
        var authData = state.auth;

        var req = await RequestApi("user-role/" + authData.user.id),
            { role, all_branch } = req.data.data;
        store.dispatch(RefreshRole({role,all_branch}));
    }
};
