import { Result } from "antd-pixel";
import { EmptyIcon } from "component/icon";
import React from "react";


const UnderConstruction = () => (
    <div className="under-cons">
        <div className="wrap">
        <Result icon={<EmptyIcon />} title="Under Constructions" />
        </div>
    </div>
)

export default UnderConstruction