import { ApplicationOutlined, CalculatorOutlined, ProductOutlined, ReportsOutlined, SalesOutlined, SettingsOutlined, TruckOutlined } from "component/icon/SidebarIcon";
import UnderConstruction from "component/layout/UnderConstrauction";
import { Navigate } from "react-router-dom";
import { PermsLazyLoader } from "utils/route/utils";

export const Menu = [
    {
        key: "incentive",
        subMenu: false,
        icon: <CalculatorOutlined />,
        //Component: null,
        permission: null,
        label: "Insentif",
        lazy: async () => {
            let { Component } = await import("view/member/incentive");
            return await PermsLazyLoader(["Super Admin", "Admin","Manager","Viewer","Admin Cabang"], Component);
        },
        children: [
            { index: true, lazy: () => import("view/member/incentive/front") },
            {
                label: "View Reports",
                key: "view-report",
                path: "r/:id",
                lazy: () => import("view/member/incentive/report")
            },
            {
                label: "View Details",
                key: "view-detail",
                path: "v/:id",
                lazy: () => import("view/member/incentive/detail")
            }
        ],
    },
    {
        key: "reports",
        subMenu: true,
        icon: <ReportsOutlined />,
        //Component: null,
        path: "reports/:subPage?",
        permission: null,
        label: "Report",
        lazy: () => import("view/member/reports"),
        children: [
            {
                index: true,
                element: <Navigate to="/reports/sales" replace />,
            },
            {
                key: "reports",
                label: "Reports",
                title: true,
            },
            {
                key: "sales",
                path: "sales",
                label: "Rekap penjualan",
                lazy: async () => {
                    let { Component } = await import("view/member/reports/sales");
                    return await PermsLazyLoader(["Super Admin", "Admin","Manager","Admin Cabang"], Component);
                },
            },
            {
                key: "product-sales",
                path: "product-sales",
                label: "Rekap penjualan produk",
                lazy: async () => {
                    let { Component } = await import("view/member/reports/product-sales");
                    return await PermsLazyLoader(["Super Admin", "Admin","Manager",,"Admin Cabang"], Component);
                },
            },
            {
                key: "grading",
                path: "grading",
                label: "Grading",
                lazy: async () => {
                    let { Component } = await import("view/member/reports/grading");
                    return await PermsLazyLoader(["Super Admin", "Admin","Manager","Admin Cabang"], Component);
                },
            },
            {
                key: "productivity",
                path: "productivity",
                label: "Productivity",
                lazy: async () => {
                    let { Component } = await import("view/member/reports/productivity");
                    return await PermsLazyLoader(["Super Admin", "Admin","Manager","Admin Cabang"], Component);
                },
            },
            {
                key: "evaluasi-income",
                path: "evaluasi-income",
                label: "Evaluasi income frontline",
                lazy: async () => {
                    let { Component } = await import("view/member/reports/sales-invoice");
                    return await PermsLazyLoader(["Super Admin", "Admin","Manager","Admin Cabang"], Component);
                },
            },
        ]
    },
    {
        key: "divider",
    },
    {
        key: "sales",
        icon: <SalesOutlined />,
        label: "Data penjualan",
        lazy: async () => {
            let { Component } = await import("view/member/sales");
            return await PermsLazyLoader(["Super Admin", "Admin","Manager","Admin Cabang"], Component);
        },
    },
    {
        key: "service",
        icon: <ApplicationOutlined />,
        label: "Data jasa/spare part",
        lazy: async () => {
            let { Component } = await import("view/member/service");
            return await PermsLazyLoader(["Super Admin", "Admin","Manager","Admin Cabang"], Component);
        },
    },
    {
        key: "products",
        icon: <ProductOutlined />,
        label: "Data produk",
        lazy: async () => {
            let { Component } = await import("view/member/product");

            return await PermsLazyLoader(["Super Admin","Admin","Manager","Admin Cabang"], Component);
        },
    },
    {
        key: "delivery",
        icon: <TruckOutlined />,
        label: "Data delivery",
        lazy: async () => {
            let { Component } = await import("view/member/delivery");

            return await PermsLazyLoader(["Super Admin","Admin","Manager","Admin Cabang"], Component);
        },
    },
    {
        key: "divider",
        permission: "Super Admin",
    },
    {
        key: "settings",
        subMenu: true,
        icon: <SettingsOutlined />,
        path: "settings/:subPage",
        label: "Settings",
        permission: "Super Admin",
        lazy: () => import("view/member/settings"),
        children: [
            {
                index: true,
                element: <Navigate to="/settings/user" replace />,
            },
            {
                key: "general",
                label: "General",
                title: true,
            },
            {
                key: "user",
                path: "user",
                label: "User",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/user"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Grading",
                key: "grading",
                path: "grading",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/grading"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/grading/all"
                            ),
                    },
                    {
                        label: "Manage",
                        key: "manage",
                        path: "m/:id",
                        lazy: () =>
                            import(
                                "view/member/settings/grading/manage"
                            ),
                        children: [
                            {
                                index: true,
                                lazy: () =>
                                    import(
                                        "view/member/settings/grading/manage/view"
                                    ),
                            },
                            {
                                label: "Edit manege",
                                key: "edit-manage",
                                path: "edit",
                                lazy: () =>
                                    import(
                                        "view/member/settings/grading/manage/edit"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                label: "Area cabang",
                key: "area-cabang",
                path: "area-cabang/:view?",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/area-cabang"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Sync data Talenta",
                key: "sync-data-talenta",
                path: "sync-data-talenta",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sync"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Biaya aplikasi",
                key: "biaya-aplikasi",
                path: "biaya-aplikasi",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/app-fee"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "SALES",
                key: "sales",
                title: true,
                Component: UnderConstruction,
            },
            {
                label: "Skema insentif cash",
                key: "skema-insentif-cash",
                path: "skema-insentif-cash",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sales/skema-cash"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Skema insentif kredit",
                key: "skema-insentif-kredit",
                path: "skema-insentif-kredit",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sales/skema-kredit"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/sales/skema-kredit/all"
                            ),
                    },
                    {
                        label: "Manage",
                        key: "manage",
                        path: "m/:id",
                        lazy: () =>
                            import(
                                "view/member/settings/sales/skema-kredit/manage"
                            ),
                        children: [
                            {
                                index: true,
                                lazy: () =>
                                    import(
                                        "view/member/settings/sales/skema-kredit/manage/view"
                                    ),
                            },
                            {
                                label: "Edit manege",
                                key: "edit-manage",
                                path: "edit",
                                lazy: () =>
                                    import(
                                        "view/member/settings/sales/skema-kredit/manage/edit"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                label: "Bonus kredit",
                key: "bonus-kredit",
                path: "bonus-kredit",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sales/bonus-kredit"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Booster",
                key: "sales-booster",
                path: "sales-booster",
                //Component: UnderConstruction,
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sales/booster"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import("view/member/settings/sales/booster/view"),
                    },
                    {
                        label: "Booster add",
                        key: "booster-add",
                        path: "f/:mode/:id?",
                        lazy: () =>
                            import("view/member/settings/sales/booster/form"),
                    },
                    {
                        label: "Booster Detail",
                        key: "booster-detail",
                        path: "v/:id?",
                        lazy: () =>
                            import(
                                "view/member/settings/sales/booster/view/Detail"
                            ),
                    },
                ],
            },
            {
                label: "Tunjangan masa kerja",
                key: "tunjangan-masa-kerja",
                path: "tunjangan-masa-kerja",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sales/tunjangan-masa-kerja"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Uang operasional",
                key: "sales-uang-operasional",
                path: "sales-uang-operasional",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/sales/uang-operasional"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/sales/uang-operasional/view"
                            ),
                    },
                    {
                        label: "Edit uang operasional",
                        key: "edit-uang-operasional",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/sales/uang-operasional/edit"
                            ),
                    },
                ],
            },
            {
                label: "MEKANIK",
                key: "mekanik",
                title: true,
                Component: UnderConstruction,
            },
            {
                label: "Skema insentif jasa",
                key: "skema-insentif-jasa",
                path: "skema-insentif-jasa",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/service-incentive"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/service-incentive/view"
                            ),
                    },
                    {
                        label: "Edit insentif jasa",
                        key: "edit-insentif-jasa",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/service-incentive/edit"
                            ),
                    },
                ],
            },
            {
                label: "Skema insentif spare part",
                key: "skema-insentif-spare-part",
                path: "skema-insentif-spare-part",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/sparepart-incentive"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/sparepart-incentive/view"
                            ),
                    },
                    {
                        label: "Edit insentif jasa",
                        key: "edit-insentif-jasa",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/sparepart-incentive/edit"
                            ),
                    },
                ],
            },
            {
                label: "Skema insentif unit entry",
                key: "skema-insentif-unit-entry",
                path: "skema-insentif-unit-entry",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/unit-entry"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/unit-entry/view"
                            ),
                    },
                    {
                        label: "Edit insentif jasa",
                        key: "edit-insentif-jasa",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/unit-entry/edit"
                            ),
                    },
                ],
            },
            
            {
                label: "Booster",
                key: "mekanik-booster",
                path: "mekanik-booster",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/booster"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Tunjangan TTL",
                key: "tunjangan-ttl",
                path: "tunjangan-ttl",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/ttl"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/ttl/view"
                            ),
                    },
                    {
                        label: "Edit uang operasional",
                        key: "edit-uang-operasional",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/ttl/edit"
                            ),
                    },
                ],
            },
            {
                label: "Uang operasional",
                key: "mekanik-uang-operasional",
                path: "mekanik-uang-operasional",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/uang-operasional"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
            {
                label: "Gross sales spare part",
                key: "gross-sales-spare-part",
                path: "gross-sales-spare-part",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/mechanic/gross-profit"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/gross-profit/view"
                            ),
                    },
                    {
                        label: "Edit insentif jasa",
                        key: "edit-insentif-jasa",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/mechanic/gross-profit/edit"
                            ),
                    },
                ],
            },
            {
                label: "DELIVERY MAN",
                key: "delivery",
                title: true,
                Component: UnderConstruction,
            },
            {
                label: "Skema insentif unit antar",
                key: "skema-insentif-unit-antar",
                path: "skema-insentif-unit-antar",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/delivery-man/skema-unit-antar"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
                children: [
                    {
                        index: true,
                        lazy: () =>
                            import(
                                "view/member/settings/delivery-man/skema-unit-antar/view"
                            ),
                    },
                    {
                        label: "Edit uang operasional",
                        key: "edit-uang-operasional",
                        path: "edit",
                        lazy: () =>
                            import(
                                "view/member/settings/delivery-man/skema-unit-antar/edit"
                            ),
                    },
                ],
            },
            {
                label: "Uang operasional",
                key: "delivery-uang-operasional",
                path: "delivery-uang-operasional",
                lazy: async () => {
                    let { Component } = await import(
                        "view/member/settings/delivery-man/uang-operasional"
                    );
                    return await PermsLazyLoader("Super Admin", Component);
                },
            },
        ],
    },
];
