import { Navigate, createBrowserRouter, redirect } from "react-router-dom"
import React from "react"
import { Menu } from "utils/constants/menu"
import UnderConstruction from "component/layout/UnderConstrauction"
import Loading from "component/loading"
import { loginUrl, logoutUrl } from "utils/constants/config"
import ErrorPage from "component/layout/ErrorPage"

const MainMenuRoute = Menu.filter(d => d.key !== "divider").map(d => {
    d.id = d.key
    d.path = d.key

    return d
}).filter(d => d.Component || d.lazy)
const router = (isAuthenticated) => {
    return createBrowserRouter([
        {
            id: 'root',
            path: "/",
            lazy: () => import("view/member"),
            errorElement: <ErrorPage />,
            loader: async() => {
                if(!isAuthenticated) {

                    if(localStorage.getItem("isLogout")){
                        localStorage.removeItem("isLogout")
                        return redirect("/logout")
                    }else{
                        return redirect(loginUrl)
                    }
                }

                return true
            },
            children: [...MainMenuRoute,
                {
                    index: true,
                    element: (
                        <Navigate to="/incentive" replace />
                    )
                },
                {
                    key:"nm",
                    path: "*",
                    element: <UnderConstruction />
                }
            ]
        },
        {
            id: "sso",
            path:"sso-callback",
            loader: () => {
                if(isAuthenticated) {
                    return redirect("/")
                }
                var searchParams = new URLSearchParams(window.location.search),
                code = searchParams.get('code')
                if(!code){
                    return redirect(loginUrl)
                }
                return code
            },
            lazy: () => import('view/Callback')
        },
        {
            id: "save-token",
            path: "save-token",
            loader: () => {
                if(isAuthenticated){
                    return redirect("/")
                }

                return false
            },
            lazy: () => import("view/SaveToken")
        },
        {
            id: "view-token",
            path: "view-token",
            lazy: () => import("view/ViewToken")
        },
        {
            id: "logout",
            path: "logout",
            loader: async() => {

                return redirect(logoutUrl)
            },
            element: <Loading.Page />
        },

    ])
}
export default router