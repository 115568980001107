import React from "react"
import { PixelIcon } from "antd-pixel"
import { toast } from "react-toastify"

const CustomToast = {
    success : (message) =>{
        toast.success(message,{icon: ({theme, type}) =>  <PixelIcon icon="done" variant="duotone" size={20} />})
    },
    error : (message) => {
        toast.error(message,{icon: ({theme, type}) =>  <PixelIcon icon="error" variant="duotone" size={20} />})
    },
    Info : (message) => {
        toast.info(message,{icon: ({theme, type}) =>  <PixelIcon icon="info" variant="duotone" size={20} />})
    },
    warning : (message) => {
        toast.warning(message,{icon: ({theme, type}) =>  <PixelIcon icon="warning" variant="duotone" size={20} />})
    }
}

export default CustomToast