import React from "react";

const CalculatorOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 22H10C5 22 3 20 3 15V9C3 4 5 2 10 2H14C19 2 21 4 21 9V15C21 20 19 22 14 22ZM16.5 8.57996V7.57996C16.5 6.74996 15.83 6.07996 15 6.07996H9C8.17 6.07996 7.5 6.75996 7.5 7.57996V8.57996C7.5 9.40996 8.18 10.08 9 10.08H15C15.83 10.08 16.5 9.39996 16.5 8.57996Z"
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M16.5 7.58V8.58C16.5 9.4 15.83 10.08 15 10.08H9C8.18 10.08 7.5 9.41 7.5 8.58V7.58C7.5 6.76 8.17 6.08 9 6.08H15C15.83 6.08 16.5 6.75 16.5 7.58Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M8.13623 14H8.14778M11.9953 14H12.0069M15.8544 14H15.866M8.13623 17.5H8.14778M11.9953 17.5H12.0069M15.8544 17.5H15.866"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        </span>
    ),
    ReportsOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    d="M18.32 12C20.92 12 22 11 21.04 7.72C20.39 5.51 18.49 3.61 16.28 2.96C13 2 12 3.08 12 5.68V8.56C12 11 13 12 15 12H18.32Z"
                    fill="transparent"
                ></path>
                <path
                    d="M19.9999 14.7C19.0699 19.33 14.6299 22.69 9.57993 21.87C5.78993 21.26 2.73993 18.21 2.11993 14.42C1.30993 9.39 4.64993 4.95 9.25993 4.01M18.3199 12.0001C20.9199 12.0001 21.9999 11.0001 21.0399 7.72006C20.3899 5.51006 18.4899 3.61006 16.2799 2.96006C12.9999 2.00006 11.9999 3.08006 11.9999 5.68006V8.56006C11.9999 11.0001 12.9999 12.0001 14.9999 12.0001H18.3199Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        </span>
    ),
    SalesOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    d="M4.40476 15.5264L8.93476 20.0564C10.7948 21.9164 13.8148 21.9164 15.6848 20.0564L20.0748 15.6664C21.9348 13.8064 21.9348 10.7864 20.0748 8.91637L15.5348 4.39637C14.5848 3.44637 13.2748 2.93637 11.9348 3.00637L6.93476 3.24637C4.93476 3.33637 3.34476 4.92637 3.24476 6.91637L3.00476 11.9164C2.94476 13.2664 3.45476 14.5764 4.40476 15.5264Z"
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M9.73486 12.2263C11.1156 12.2263 12.2349 11.107 12.2349 9.72632C12.2349 8.34561 11.1156 7.22632 9.73486 7.22632C8.35415 7.22632 7.23486 8.34561 7.23486 9.72632C7.23486 11.107 8.35415 12.2263 9.73486 12.2263Z"
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                ></path>
                <path
                    d="M13.2349 17.2263L17.2349 13.2263"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        </span>
    ),
    ApplicationOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    d="M9.93014 2.48004L4.59014 5.44004C3.38014 6.11004 2.39014 7.79004 2.39014 9.17004V14.82C2.39014 16.2 3.38014 17.88 4.59014 18.55L9.93014 21.52C11.0701 22.15 12.9401 22.15 14.0801 21.52L19.4201 18.55C20.6301 17.88 21.6201 16.2 21.6201 14.82V9.17004C21.6201 7.79004 20.6301 6.11004 19.4201 5.44004L14.0801 2.47004C12.9301 1.84004 11.0701 1.84004 9.93014 2.48004Z"
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M3.16992 7.43994L11.9999 12.5499L20.7699 7.46994"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M12 21.61V12.54"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        </span>
    ),
    ProductOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    d="M4.5 10H19.5V16C19.5 18.8284 19.5 20.2426 18.6213 21.1213C17.7426 22 16.3284 22 13.5 22H10.5C7.67157 22 6.25736 22 5.37868 21.1213C4.5 20.2426 4.5 18.8284 4.5 16V10Z"
                    fill="transparent"
                ></path>
                <path
                    d="M10.25 14H13.75M4.5 10H19.5V18C19.5 19.8856 19.5 20.8284 18.9142 21.4142C18.3284 22 17.3856 22 15.5 22H8.5C6.61438 22 5.67157 22 5.08579 21.4142C4.5 20.8284 4.5 19.8856 4.5 18V10ZM6 10H18C19.8856 10 20.8284 10 21.4142 9.41421C22 8.82843 22 7.88562 22 6C22 4.11438 22 3.17157 21.4142 2.58579C20.8284 2 19.8856 2 18 2H6C4.11438 2 3.17157 2 2.58579 2.58579C2 3.17157 2 4.11438 2 6C2 7.88562 2 8.82843 2.58579 9.41421C3.17157 10 4.11438 10 6 10Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        </span>
    ),
    SettingsOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 11.12V12.88C2 13.92 2.85 14.78 3.9 14.78C5.71 14.78 6.45 16.06 5.54 17.63C5.02 18.53 5.33 19.7 6.24 20.22L7.97 21.21C8.76 21.68 9.78 21.4 10.25 20.61L10.36 20.42C11.27 18.85 12.75 18.85 13.65 20.42L13.76 20.61C14.23 21.4 15.25 21.68 16.04 21.21L17.77 20.22C18.68 19.7 18.99 18.54 18.47 17.63C17.56 16.06 18.3 14.78 20.11 14.78C21.16 14.78 22.01 13.92 22.01 12.88V11.12C22.01 10.07 21.15 9.22 20.11 9.22C18.3 9.22 17.56 7.94 18.47 6.37C18.99 5.47 18.68 4.3 17.77 3.78L16.04 2.79C15.25 2.32 14.23 2.6 13.76 3.39L13.65 3.58C12.74 5.15 11.26 5.15 10.36 3.58L10.25 3.39C9.78 2.6 8.76 2.32 7.97 2.79L6.24 3.78C5.33 4.3 5.02 5.47 5.54 6.37C6.45 7.94 5.71 9.22 3.9 9.22C2.85 9.22 2 10.08 2 11.12ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                    fill="transparent"
                ></path>
                <path
                    d="M2 12.88V11.12C2 10.08 2.85 9.22 3.9 9.22C5.71 9.22 6.45 7.94 5.54 6.37C5.02 5.47 5.33 4.3 6.24 3.78L7.97 2.79C8.76 2.32 9.78 2.6 10.25 3.39L10.36 3.58C11.26 5.15 12.74 5.15 13.65 3.58L13.76 3.39C14.23 2.6 15.25 2.32 16.04 2.79L17.77 3.78C18.68 4.3 18.99 5.47 18.47 6.37C17.56 7.94 18.3 9.22 20.11 9.22C21.15 9.22 22.01 10.07 22.01 11.12V12.88C22.01 13.92 21.16 14.78 20.11 14.78C18.3 14.78 17.56 16.06 18.47 17.63C18.99 18.54 18.68 19.7 17.77 20.22L16.04 21.21C15.25 21.68 14.23 21.4 13.76 20.61L13.65 20.42C12.75 18.85 11.27 18.85 10.36 20.42L10.25 20.61C9.78 21.4 8.76 21.68 7.97 21.21L6.24 20.22C5.33 19.7 5.02 18.53 5.54 17.63C6.45 16.06 5.71 14.78 3.9 14.78C2.85 14.78 2 13.92 2 12.88Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    stroke-miterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        </span>
    ),
    TruckOutlined = () => (
        <span className="anticon custom">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20ZM14 20C14 18.8954 14.8954 18 16 18C14.9 18 14 18.9 14 20ZM16 18C17.1 18 18 18.9 18 20C18 18.8954 17.1046 18 16 18Z"
                    fill="currentColor"
                />
                <path
                    d="M12 14H13C14.1 14 15 13.1 15 12M15 12V2H6C4.5 2 2 2 2 4M15 12V5H16.84C17.56 5 18.22 5.39001 18.58 6.01001L20.29 9M2 17C2 18.66 3.34 20 5 20H6M6 20C6 18.9 6.9 18 8 18M6 20C6 21.1046 6.89543 22 8 22C9.10457 22 10 21.1046 10 20M6 20C6 18.8954 6.89543 18 8 18M8 18C9.1 18 10 18.9 10 20M8 18C9.10457 18 10 18.8954 10 20M10 20H14M14 20C14 18.9 14.9 18 16 18M14 20C14 21.1046 14.8954 22 16 22C17.1046 22 18 21.1046 18 20M14 20C14 18.8954 14.8954 18 16 18M16 18C17.1 18 18 18.9 18 20M16 18C17.1046 18 18 18.8954 18 20M18 20H19C20.66 20 22 18.66 22 17V14M22 14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29M22 14V12L20.29 9M2 8H8M2 11H6M2 14H4"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </span>
    );

export {
    CalculatorOutlined,
    ReportsOutlined,
    SalesOutlined,
    ApplicationOutlined,
    ProductOutlined,
    SettingsOutlined,
    TruckOutlined
};
