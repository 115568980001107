import { all, call, put, takeLatest } from "redux-saga/effects";
import { LogInError, LogIn, LogInSuccess, LogOut } from "../slices/auth";
import { DoLogIn } from "controller/Auth";
import CustomToast from "component/Toast";
function* getLoginHandler(props) {
    try {
        
        const req = yield call(DoLogIn, props.payload);
        const ori = window.location.origin;
        localStorage.setItem("login-" + ori, "login" + Math.random());
        yield put(LogInSuccess(req));
    } catch (e) {
        var msg = e.message;
        if (e.response && e.response.status === 422) {
            if (e.response.data && e.response.data.errors) {
                const { errors } = e.response.data;
                for (const attribute in errors) {
                    if (errors.hasOwnProperty(attribute))
                        msg = errors[attribute][0];
                }
            }
        }

        yield put(LogInError(msg));
        CustomToast.error("Login error :"+msg)
    }
}

function* LogoutHandler(props) {
    const ori = window.location.origin;
    localStorage.setItem("logout-" + ori, "logout" + Math.random());
    //redirect(logoutUrl)
    localStorage.setItem("isLogout",true)
    yield console.log("logout success");
}

function* AuthSaga() {
    yield all([
        takeLatest(LogIn.type, getLoginHandler),
        takeLatest(LogOut.type, LogoutHandler),
    ]);
}

export default AuthSaga;
