import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from ".";
import Loading from "component/loading";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/id";
import { configMoment } from "utils/helper/data";
moment.locale("id");


const RouteProvider = () => {
    const {isAuthenticated} = useSelector(state => state.auth, shallowEqual),
    
    
    LogoutAllTab = (event) =>{
        var ori = window.location.origin
        if (event.key === 'logout-'+ori) {
            window.location.reload()
        }
    }

    useEffect(()=>{
        configMoment();
        window.addEventListener('storage', LogoutAllTab);
        return(()=>{
            window.removeEventListener('storage',LogoutAllTab)
        })
    },[])
    return(
        <RouterProvider router={router(isAuthenticated)} fallbackElement={<Loading.Page />} />
    )
    
}

export default RouteProvider