import { config } from "utils/constants/config";
import moment from "moment";
import { toMoment } from "./form";

export const configMoment = () => {
    var config = {
        months: [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
        ],
        monthsShort: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Agu",
            "Sep",
            "Okt",
            "Nov",
            "Des",
        ],
        relativeTime: {
            s: "%d detik",
            m: "%d menit",
            h: "%d jam",
            d: "%d hari",
            w: "%d minggu",
            M: "%d bulan",
            MM: "%d bulan",
            y: "%d tahun",
        },
        meridiem: function (h, m, isLower) {
            if (h > 12) return isLower ? "pm" : "PM";
            else return isLower ? "am" : "AM";
        },
    };
    moment.updateLocale("en", config);
    moment.updateLocale("id", config);
};

export const textAreatoText = (txt) => {
    if (txt === " " || !txt) {
        return "--";
    }
    var t = txt.replace(/\n/g, "<br />\n");
    return <p dangerouslySetInnerHTML={{ __html: t }}></p>;
};

export const textProcess = (text, processTextarea = true) => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g,
        replacedText = text.replace(urlRegex, function (url, b, c) {
            url = c === "www." ? "http://" + url : url;
            return '<a href="' + url + '" target="_blank">' + url + "</a>";
        });
    return processTextarea ? textAreatoText(replacedText) : replacedText;
};

export const view = (data, suffix = null, prefix = null, emptyStr = "--") => {
    if (data === " " || data == null || !data || data === "0") return emptyStr;
    if (prefix) data = prefix + data;
    if (suffix) data = data + suffix;
    return data;
};

export const viewDecimals = (
    data,
    decimalPlace = 2,
    suffix = null,
    prefix = null,
    emptyStr = "--"
) => {
    var d = view(data, null, null, emptyStr);
    if (d === emptyStr) {
        return d;
    }

    var dn = getNumber(d);
    var p = d.toString().split(".");
    if (p.length === 2 && p[1].length > decimalPlace) {
        dn = dn.toFixed(2);
    }
    if (prefix) dn = prefix + dn;
    if (suffix) dn = dn + suffix;
    return dn;
};

export const viewDate = (
    data,
    format = config.dateFormat,
    formatData = "YYYY-MM-DD"
) => {
    const date = moment(data, formatData);
    //console.log(formatData)
    if (!data || !date.isValid()) return "--";
    return date.format(format);
};

export const viewDateTime = (
    data,
    dataFormat = null,
    format = config.dateTimeFormat
) => {
    dataFormat = dataFormat ?? "YYYY-MM-DD HH:mm:ss";
    return viewDate(data, format, dataFormat);
};

export const sortByAlpabet = (a, b, index) => {
    var A = a[index] ? a[index] : "zzzzzzzzzzzzzzzzzzzzzzz",
        B = b[index] ? b[index] : "zzzzzzzzzzzzzzzzzzzzzzz";
    return deleteSpaceAtStart(A).localeCompare(deleteSpaceAtStart(B));
};
export const sortByNumber = (a, b, index) => {
    var A = a[index] ? a[index] : "9999999999999999999",
        B = b[index] ? b[index] : "9999999999999999999";
    return A - B;
};
export const sortByDate = (a, b, index) => {
    var date1 = a[index] ? a[index] : "1900-01-01",
        date2 = b[index] ? b[index] : "1900-01-01";

    return moment.utc(date1).diff(moment.utc(date2));
};

export const sortByDateTime = (a, b, index) => {
    var date1 = a[index] ? a[index] : "1900-01-01 00:00:00",
        date2 = b[index] ? b[index] : "1900-01-01 00:00:00",
        format = "YYYY-MM-DD hh:mm:ss";

    return moment.utc(date1, format).diff(moment.utc(date2, format));
};
export const sortByTime = (a, b, index) => {
    var date1 = a[index] ? a[index] : "00:00:00",
        date2 = b[index] ? b[index] : "00:00:00";

    return moment.utc(date1, "HH:mm:ss").diff(moment.utc(date2, "HH:mm:ss"));
};

export const sortByYear = (a, b, index) => {
    var year1 = a[index] ? a[index] : "9999",
        year2 = b[index] ? b[index] : "9999";

    year1 = moment.isMoment(year1) ? year1.format("YYYY") : year1;
    year2 = moment.isMoment(year2) ? year2.format("YYYY") : year2;

    return moment.utc(year1, "YYYY").diff(moment.utc(year2, "YYYY"));
};

export const sortByAreaAndName = (data) => {
    data.sort((a, b) => sortByNumber(a, b, "area"));
    data.sort((a, b) => {
        if (a.area !== b.area) {
            return false;
        }
        var A = a.name.toLowerCase(),
            B = b.name.toLowerCase();
        return A.localeCompare(B);
    });

    return data;
};
const randomColor = (text) => {
    var hash = 0;
    if (text === 0) return hash;
    for (var i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = "#";
    for (var n = 0; n < 3; n++) {
        var value = (hash >> (n * 8)) & 255;
        color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
};
export const defaultAvatar = (name) => {
    name = name.split(" -").join();
    const initial = name
            .split(" ")
            .map((word) => word[0])
            .join("")
            .substring(0, 2)
            .toUpperCase(),
        color = randomColor(name);

    return { initial, color };
};

export const firstCapWord = (text = "") =>
    text
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
export const getFileInfo = (fileName) => {
    var ext = fileName.split(".").pop().toLowerCase(),
        imageExt = ["jpg", "jpeg", "gif", "png"],
        icon = "file",
        type = null;
    if (imageExt.includes(ext)) {
        icon = "img";
        type = "img";
    } else if (ext === "pdf") {
        icon = "pdf-document";
        type = "pdf";
    } else if (["doc", "docx"].includes(ext)) {
        icon = "word-document";
        type = "doc";
    } else if (["xls", "xlsx"].includes(ext)) {
        icon = "excel-document";
        type = "doc";
    } else if (ext === "zip") {
        icon = "zip";
        type = "zip";
    }

    return { ext, icon, type };
};

export const currency = (
    number = "0",
    symbol = "Rp",
    emptyStr = "--",
    suffix = ""
) => {
    number = Number(number);
    number = isNaN(number) ? 0 : number;
    var isMin = false;
    if (number < 0) {
        isMin = true;
        number = Math.abs(number);
    }
    number = number.toString();

    if (number === "0" || number === "") return emptyStr;
    //var sysnum = symbol ? symbol + number : number;
    symbol = symbol ?? "";
    symbol = isMin ? "- " + symbol : symbol;
    return symbol + number.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + suffix;
};

export const isArray = function (a) {
    return !!a && a.constructor === Array;
};

export const removeDoubleInArray = (array) =>
    array.filter((i, index, self) => self.indexOf(i) === index);

export const diffDate = (from, to = moment()) => {
    from = toMoment(from);
    const year = to.diff(from, "year");
    from.add(year, "years");
    const month = to.diff(from, "months"),
        y = year > 0 ? year + " years " : "",
        m = month > 0 ? month + " months" : "";
    return y + m;
};

export const findInArray = (db, keyword, index = "key") => {
    return db.filter((d) => d[index] === keyword);
};

export const deleteNullinObj = (obj = {}) => {
    Object.keys(obj).forEach((key) => {
        var t = obj[key];
        if (t === null || t === "" || t === undefined) {
            delete obj[key];
        }
    });
    return obj;
};

export const intToWord = (num) => {
    var a = [
        "",
        "satu ",
        "dua ",
        "tiga ",
        "empat ",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
        "dua belas ",
        "tiga belas",
        "empat belas",
        "lima belas",
        "enam belas",
        "tujuh belas",
        "delapan belas",
        "sembilan belas",
    ];
    var b = [
        "",
        "",
        "dua puluh",
        "tiga puluh",
        "empat puluh",
        "lima puluh",
        "enam puluh",
        "tujuh puluh",
        "delapan puluh",
        "sembilan puluh",
    ];

    if ((num = num.toString()).length > 9) return "overflow";
    var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
        n[1] !== 0
            ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "juta "
            : "";
    str +=
        n[2] !== 0
            ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "ratus ribu "
            : "";
    str +=
        n[3] !== 0
            ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "ribu "
            : "";
    str +=
        n[4] !== 0
            ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "ratus "
            : "";
    str +=
        n[5] !== 0
            ? (str !== "" ? "" : "") +
              (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
            : "";
    str = str.replaceAll("satu ", "se");
    return str;
};

export const pxToRem = (num) => {
    return parseFloat(num) / 16 + "rem";
};

export const groupArrayBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const isExistingPeriod = (date) => date.isSameOrBefore(moment());

export const UID = () =>
    Date.now().toString(36) + Math.random().toString(36).substr(2);

export const pushArray = (arr, val) => {
    val = Array.isArray(val) ? val : [val];
    var array = [...arr, ...val];
    array = array.filter((d, i) => array.indexOf(d) === i);
    return array;
};

export const getNumber = (val, emptyVal = 0) => {
    if (typeof val === "number") return val;
    if (!val) return emptyVal;
    var v = parseInt(val.replace(/\D/g, ""));
    return isNaN(v) ? emptyVal : v;
};

export const dateAgo = (date) => {
    date = date + " 12:00 AM";
    var startDate = new Date(date.replace(/-/g, "/"));
    var endDate = new Date();

    var diffDate = new Date(new Date() - startDate);
    var yearDiff = diffDate.toISOString().slice(0, 4) - 1970;
    var monthDiff = diffDate.getMonth();
    //var dayDiff   = diffDate.getDate()-1;

    //monthDiff = monthDiff + yearDiff*12

    var msec = endDate - startDate;
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    //var days  = Math.floor(hrs / 24);

    var label = "";
    if (mins <= 30) {
        label += "Baru saja";
    } else if (hrs <= 24) {
        label += "Hari ini";
    } else {
        if (yearDiff > 0) {
            label += yearDiff + " tahun ";
        }
        if (monthDiff > 0) {
            label += monthDiff + " bulan";
        }
    }

    return label;
};
export const downloadFile = (url, fileName = null) =>
    new Promise(async (resolve, reject) => {
        try {
            const link = document.createElement("a");
            fileName = fileName ?? url.split("/").pop();
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            //link.remove();
            resolve(true);
        } catch (e) {
            console.log(e.message);
            reject(e);
        }
    });

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const deleteSpaceAtStart = (str) => {
    if(!str) return str;
    return str.replace(/^\s+/g, '');
    
} 
