import { store } from "index"

export const PermsLazyLoader = async(permRole,Component) => {
    let {Forbidden} = await import("component/Forbidden")
    var state = store.getState(),
    {role} =  state.auth.user
    if(typeof(permRole) === "string"){
        permRole = [permRole]
    }
    if(permRole.includes(role)){
        return {Component}
    }else{
        return{Component: Forbidden}
    }
} 