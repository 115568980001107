import { Button, Result } from "antd-pixel";
import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError(),
    navigate = useNavigate(),
    handleClick = () => {
        navigate(-1)
    }
    return (
        <Result

            status="500"
            title="Oops!"
            subTitle="Sorry, an unexpected error has occurred"
            extra={<><div className="mb-24"><div className="error-page-message">{error.message}</div></div><Button onClick={handleClick} type="primary">Back</Button></>}
        />
    );
}
