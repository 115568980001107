import { Loading3QuartersOutlined } from "@ant-design/icons/lib/icons";
import { Spin } from "antd-pixel";
import React from "react";

const Loading = ({ spinning = "false", ...props }) => {
    return (
        <Spin
            spinning={spinning}
            {...props}
            indicator={
                <Loading3QuartersOutlined style={{ fontSize: "24px" }} spin />
            }
        >
            {props.children && props.children}
        </Spin>
    );
};

const LoadingPage = (props) => {
    return (
        <div className="page-loading full">
            <Loading size="large" {...props} />
        </div>
    );
};

Loading.Page = LoadingPage;

export default Loading;
