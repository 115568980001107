import { createTransform } from 'redux-persist';

const transformPersistence = (inboundState, config) => {
  inboundState = inboundState || {};

  if (config.autoExpire && !inboundState[config.persistedAtKey]) {
    inboundState = Object.assign({}, inboundState, {
      [config.persistedAtKey]: (new Date()).getTime()
    });
  }

  return inboundState;
};

const transformRehydrate = (outboundState, config) => {
  outboundState = outboundState || {};

  if (config.expireSeconds && outboundState[config.persistedAtKey]) {
    const startTime = (new Date(outboundState[config.persistedAtKey])).getTime();
    const endTime = (new Date()).getTime();

    const duration = endTime - startTime;
    const seconds = duration / 1000;

    if (seconds > config.expireSeconds) {
      return Object.assign({}, config.expiredState);
    }
  }

  return outboundState;
};


function expireReducer(reducerKey, config = {}) {

  const defaults = {
    persistedAtKey: '__persisted_at',
    expireSeconds: null,
    expiredState: {},
    autoExpire: false
  };

  config = Object.assign({}, defaults, config);

  return createTransform(
    (inboundState) => transformPersistence(inboundState, config),
    (outboundState) => transformRehydrate(outboundState, config),
    {
      whitelist: [reducerKey]
    }
  );
}

export default expireReducer