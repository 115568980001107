import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./slices";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga";
import storage from "redux-persist/lib/storage";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import expireReducer from "./expireReducer";
import { initialState } from "./slices/auth";
import { encryptTransform } from "redux-persist-transform-encrypt";

const sagaMiddleware = createSagaMiddleware(),
    persistConfig = {
        key: "root",
        version: 1,
        storage,
        whitelist: ["auth"],
        transforms: [
            expireReducer("auth", {
                expireSeconds: 60 * 60 * 12,
                autoExpire: true,
                expiredState: initialState,
            }),
            encryptTransform({
                secretKey: "wari2802!",
                onError: function (error) {
                    // Handle the error.
                    console.log("error encript", error);
                },
            }),
        ],
    },
    persistedReducer = persistReducer(persistConfig, rootReducers);

const configureAppStore = () => {
    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                    ],
                },
            }).concat(sagaMiddleware),
    });

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureAppStore;
