//import {ReactComponent as EmptyImg} from "./empty.svg"
export{ReactComponent as EmptyIcon} from "./empty.svg"
export{ReactComponent as HelpIcon} from "./cs.svg"
export{ReactComponent as ArrowCollapse} from "./arrow-collapse.svg"
export{ReactComponent as ArrowExpand} from "./arrow-expand.svg"
export{ReactComponent as AddIcon} from "./add.svg"

export const CustomIcon = (props) =>{
    return(
        <span className={`anticon custom ${props.className}`} >
            <props.icon />
        </span>
    )
}




    

